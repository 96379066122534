var Core = require('core/core.js');

console.log("loaded main menu");

Core.createModule ("mdl_MainMenu", {
	el : ".coreModule.mdl_MainMenu",
	popupTimeout: 0,
	popupMouseOver: false,
	menuMouseOver: false,
	currentPopup : null,
	popupShowDelay : 200,
	submenuShowDelay : 200,
	intervalHandle : null,
	cursorMoved: false,
	moveCount : 0,
	subMenuMouseOver: false,
	intervalHandleSub : null,

	init : function ()
	{
		var self = this;
		self.el = $(self.el);

		// activate menu from global var
		self.activateMenu();

		if (self.el.exists()) {
			console.log("mdl_MainMenu");

			// find existing submenus
			self.el.find(".short-menu li").each(function(){

				var $menuElement = $(this),
					dataId = $menuElement.attr("data-id");

				if (dataId) {
					var $sub = self.el.find('.menu-deals .menu-deal[data-id="'+dataId+'"]');

					if ($sub.exists()) {
						$menuElement.addClass("submenu");

						$menuElement.mouseover(function(){

							if (self.cursorMoved) {
								self.menuMouseOver = true;
								self.showPopup($menuElement, $sub);
							}

						});

						$menuElement.mouseout(function(){
							self.menuMouseOver = false;
							self.hidePopup();
						});

						$sub.mouseover(function(){
							if (self.cursorMoved) {
								self.popupMouseOver = true;
							}
						});

						$sub.mouseout(function(){
							self.popupMouseOver = false;
							self.hidePopup();
						});

					}
				}
			});


			self.el.find(".short-menu li a").click(function(e)
			{
				self.hidePopupFast();
			});

            self.el.find(".short-menu li a").each(function (e) {

            	try {
                    var $link = $(this),
                        url = $(this).attr('href') || "";
                    url = url.toLowerCase();

                    if (url.indexOf("http://") == 0) {
                        $link.attr('target','_blank');
                    }
				}
				catch (err) {
            		console.log("error",err);
				}


            });

			self.bindControls();

			self.updateNewItems();

			self.el.find(".short-menu li.new").each(function()
			{
				$(this).append('<div class="ui-icon new-sign"></div>');
			});
		}
	},

	activateMenu: function()
	{
		var self = this,
			activeMenuId = null;

		try
		{
			activeMenuId = window.mainMenuSelectedItem;
		}
		catch(e)
		{
			console.log("mainMenuSelectedItem is not exists");
		}

		if (activeMenuId) {
			$('.top-menu nav a[data-id="'+activeMenuId+'"]').addClass("active");
		}

	},

	updateNewItems: function()
	{
		var self = this,
			newItems = null;

		try
		{
			newItems = window.mainMenuNewItems;
		}
		catch(e)
		{
			console.log("mainMenuNewItems is not exists");
		}

		if (newItems && Object.prototype.toString.call( newItems ) === '[object Array]') {

			for (var i in newItems) {
				var e = newItems[i],
					$menuElement = self.el.find('.short-menu li[data-id="'+e+'"]');
				$menuElement.addClass("new");
			}
		}
	},

	showPopup: function($menuElement, $popup)
	{
		var self = this;

		clearTimeout(self.intervalHandle);

		self.intervalHandle = setTimeout(function(){
			if (self.menuMouseOver) {
				self.showPopupFast($menuElement, $popup);
			}

		},self.popupShowDelay);
	},

	showPopupFast:function($menuElement, $popup)
	{
		var self = this;
		self.el.find(".short-menu li a, .short-menu li").removeClass("on");
		$menuElement.addClass("on").find("a").addClass("on");

		self.el.addClass("menu-open");

		self.el.find(".menu-deals .menu-deal").hide();
		$popup.show();

		self.showSubMenuItemFast($popup,$popup.find(".deals"), $popup.find(".main-deals ul li").eq(0), $popup.find(".deal-item").eq(0));

		// close other dialogs
		try {
			dPicker.closeDialog();
		}
		catch(e)
		{
			console.log("cannot close dPicjer",e);
		}

		try {
			Core.utils.datePicker.closeAllDatepickers();
		}
		catch(e)
		{
			console.log("cannot closeAllDatepickers",e);
		}

		try {
			var m = Core.getModuleByName("mdl_ACInput");
			m.hideAll();
		}
		catch(e)
		{
			console.log("cannot hide mdl_ACInput",e);
		}

		try {
			var m = Core.getModuleByName("mdl_DesignedSelect");
			m.hideAll();
		}
		catch(e)
		{
			console.log("cannot hide mdl_DesignedSelect",e);
		}

		Core.publisher.publish("MAIN_MENU_OPENED");

	},

	hidePopup : function()
	{
		var self = this;


		setTimeout(function(){
			if (!self.popupMouseOver && !self.menuMouseOver) {
				self.hidePopupFast();

			}

		},self.popupTimeout);
	},

	hidePopupFast: function()
	{
		var self = this;
		self.el.find(".menu-deals .menu-deal").hide();
		self.el.find(".short-menu li a, .short-menu li").removeClass("on");
		self.el.removeClass("menu-open");

		self.currentPopup = null;
	},

	bindControls: function()
	{
		var self = this;


		self.el.find(".menu-deal .content .btn-close").click(function(e){

			e.preventDefault();

			self.hidePopupFast();

		});

		$("body").mousemove(function(e) {

			if (++self.moveCount > 1) {
				self.cursorMoved = true;
			}
		});

		self.bindSubmenuControls();

	},

	bindSubmenuControls: function()
	{
		var self = this;

		self.el.find(".menu-deal").each(function(){
			var $sub = $(this),
				$items = $sub.find(".deals");

			$sub.find(".main-deals ul li").each(function(index, $el){
				var $link = $($el).find("a, .item"),
					$subItem = $items.find(".deal-item").eq(index);


				$link.mouseover(function(){

					self.subMenuMouseOver = true;
					self.showSubMenuItem($sub, $items, $($el), $subItem);
				});

				$link.mouseout(function(){
					self.subMenuMouseOver = false;
					self.hidePopup();
				});

			});

			self.showSubMenuItemFast($sub,$items, $sub.find(".main-deals ul li").eq(0), $items.find(".deal-item").eq(0));


		});
	},

	showSubMenuItem: function($sub, $items, $menuElement, $itemToShow)
	{
		var self = this;

		clearTimeout(self.intervalHandleSub);

		self.intervalHandleSub = setTimeout(function(){
			if (self.subMenuMouseOver) {

				self.showSubMenuItemFast($sub, $items, $menuElement, $itemToShow);
			}

		},self.submenuShowDelay);
	},

	showSubMenuItemFast: function($sub, $items, $menuElement, $itemToShow)
	{
		var self = this;

		$items.find(".deal-item").hide();
		$itemToShow.show();
		$sub.find("ul li").removeClass("on");
		$menuElement.addClass('on');

	}

});

