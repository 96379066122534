var Publisher = require('common/publisher');
console.log("loading core...");
var Core = (function() {
	var modules = {};
	var utils = {};
	var components = [];
	var isOpenDialog = false;
	var isMobileDevice = false;
	var pageURL;

	return {
		init : function ()
		{
			console.log("Initializing core");
			this.utils = utils;
			this.publisher = Publisher;

			this.pageURL = location.href.replace(location.hash,"");

			// detect if mobile device
			if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
				isMobileDevice = true;
			}
			else {
				isMobileDevice = false;
			}

			var startTime = new Date().getTime(),
				log = [],
				st,
				tt;

			// init utils
			for (var name in utils) {
				try{
					st =  new Date().getTime();
					utils[name].init();
				}
				catch(e)
				{
					console.log("Util INIT failed: ",name, e.message);
				}
				finally
				{
					tt = new Date().getTime() - st;
					log.push("  "+name+" "+tt);
				}
			}

			// init modules
			for (var name in modules) {
				try{
					st =  new Date().getTime();
					modules[name].init();
				}
				catch(e)
				{
					console.log("Module INIT failed: ", name, e.message);
				}
				finally
				{
					tt = new Date().getTime() - st;
					log.push("  "+name+" "+tt);
				}
			}

			console.log("Intialization time:")
			for (var i in log)
			{
				console.log(log[i]);
			}

			var endTime = new Date().getTime();
			console.log("Total time (ms):" + (endTime-startTime));

			if (navigator.userAgent.indexOf("MSIE 10") > -1) {
				document.body.classList.add("ie10");
			}

		},
		createModule: function (name, obj){
			modules[name] = obj;
		},
		createUtil: function (name, obj){
			utils[name] = obj;
		},

		dialogBox:function(message)
		{
			alert(message);
		},

		openLocation : function(url)
		{
			console.log(url);
			window.location.href = url;
		},

		openLocationNewPage : function(url, windowName)
		{
			var newWnd = window.open(url, windowName);
			if (newWnd != null && newWnd.focus)
				newWnd.focus();

		},

		openDialogNotification : function()
		{
			console.log("openDialogNotification");
			isOpenDialog = true;
		},

		closeDialogNotification : function()
		{
			console.log("closeDialogNotification")
			isOpenDialog = false;
		},

		isOpenDialog : function()
		{
			return isOpenDialog;
		},

		isMobileDevice : function()
		{
			return isMobileDevice;
		},

		registerComponent : function(trg, name)
		{
			if (components[name]) {
				console.log(name + " component already registered");
			}

			components[name] = trg;
		},

		getComponent : function(name)
		{
			if (components[name]) {

				return components[name];
			}

			return false;
		},

		getModuleByName : function(name)
		{
			if (modules[name]) {

				return modules[name];
			}

			return false;
		},

		getQueryStringParamsArray: function()
		{
			var urlParams = [],
			match,
			pl     = /\+/g,  // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
			query  = window.location.search.substring(1);

			while (match = search.exec(query)) {

				urlParams.push({"name":decode(match[1]), "value":decode(match[2])})
			}

			return urlParams;
		},

		getQueryStringParamsString: function()
		{
			var params 	= this.getQueryStringParamsArray(),
				str		= "",
				i,
				p;

			for (i in params) {
				p = params[i].name+"="+params[i].value;
				str += (str!="") ? "&"+p : p;
			}

			return str;
		},

		getQueryStringParamsObject: function()
		{
			var urlParams = {},
			match,
			pl     = /\+/g,  // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
			query  = window.location.search.substring(1);

			while (match = search.exec(query)) {
				urlParams[decode(match[1])] = decode(match[2]);
			}

			return urlParams;
		},

		getQueryStringHashParamsObject: function()
		{
			var urlParams = [],
			match,
			pl     = /\+/g,  // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
			query  = window.location.hash;

			if (query.indexOf("#!/") != 0){
				return urlParams;
			}

			query=query.substring(3);

			while (match = search.exec(query)) {

				urlParams[decode(match[1])] = decode(match[2]);
			}

			return urlParams;
		},

		getQueryStringHashParamsArray: function()
		{
			var urlParams = [],
			match,
			pl     = /\+/g,  // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
			query  = window.location.hash;

			if (query.indexOf("#!/") != 0){
				return urlParams;
			}

			query=query.substring(3);

			while (match = search.exec(query)) {

				urlParams.push({"name":decode(match[1]), "value":decode(match[2])})
			}

			return urlParams;
		},

		getQueryStringHashParamsString: function()
		{
			var params 	= this.getQueryStringHashParamsArray(),
				str		= "",
				i,
				p;

			for (i in params) {
				p = params[i].name+"="+params[i].value;
				str += (str!="") ? "&"+p : p;
			}

			return str;
		},

		setQueryStringHashParamsString: function(hashParamsString)
		{
			var url = this.pageURL + "#!/" + hashParamsString;
			console.log("setQueryStringHashParamsString",url);

			window.location.href=url;
		},

		getQueryStringParam: function(paramName, caseSensitive, defaultValue)
		{
			var params = this.getQueryStringParamsArray(),
				i,
				pname,
				value,
				condition1,
				condition2;

			for (i in params) {
				pname = params[i].name+"";
				value = params[i].value;

				condition1 = caseSensitive && (pname == paramName);
				condition2 = !caseSensitive && (_BGD_Url.decode(pname).toLowerCase() == _BGD_Url.decode(paramName).toLowerCase());

				if (condition1 || condition2) {
					return value;
				}
			}

			if (defaultValue) {
				return defaultValue;
			}
			else {
				return false;
			}
		},

		log: function()
		{
		    if(console && this.getPageConfigValue("debug", false)){
		        console.log.apply(console, arguments);
		    }
		},

		getPageConfigValue: function(valueName, defaultValue)
		{
			var value;

			try{
				value = (typeof pageConfig[valueName] !== 'undefined') ? pageConfig[valueName] : defaultValue;
			}
			catch(e) {
				console.log(e.message);

				value = defaultValue;
			}

			return value;
		},

		getCurrentDateTime: function()
		{
			if (window.server_time) {
				return server_time;
			}

			return new Date();
		},

		getBrowser: function()
		{
			return $.browser;
		}

	}

}());

if (window && !window.Core) {

    window.Core = Core;
    console.log (window.Core === Core);
}

if (module) {
    if (window && !window.Core) {
        module.exports = Core;
    } else {
        module.exports = window.Core;
    }

}

$(document).ready(function()
{
   if (typeof console === "undefined" || typeof console.log === "undefined") {
	 console = {};
	 console.log = function() {};
   }

	jQuery.fn.exists = function(){return this.length>0;}

	Core.init();
});