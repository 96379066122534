var Core = require('core/core'),
	moment = require('moment');

Core.createUtil ("mdl_tooltip", {
	init : function()
	{
		var self = this;

		console.log("new Tooltip LIB init");

		var ltr = $('<div/>', {
			"id":     "tooltipLTRStyle",
			"class":  'tooltipLTRStyle',
			"style": "display:none;",
			"text": 	''
		});

		var rtl = $('<div/>', {
			"id":     "tooltipRTLStyle",
			"class":  'tooltipRTLStyle',
			"style": "display:none;",
			"text": 	''
		});

		$('body').append(ltr).append(rtl);

		var $e = $("#tooltipLTRStyle");
		window.ltr_tooltipstyle = {
			  padding: $e.css("padding-top") + " " + $e.css('padding-right'),
			  background: $e.css("background-color"),
			  color: $e.css("color"),
			  direction: $e.css("direction"),
			  textAlign: $e.css("text-align"),
			  border: { width: 0 },
			  name: 'dark',
			  width: { max: 1000 }
		   };

		var $e = $("#tooltipRTLStyle");
		window.rtl_tooltipstyle = {
			  padding: $e.css("padding-top") + " " + $e.css('padding-right'),
			  background: $e.css("background-color"),
			  color: $e.css("color"),
			  direction: $e.css("direction"),
			  textAlign: $e.css("text-align"),
			  border: { width: 0 },
			  name: 'dark',
			  width: { max: 300 }
		   };

	}
});


Core.createModule ("mdl_customSelect", {
	init : function()
	{
		var self = this;

		Core.publisher.subscribe('onSelectChange', function (_select) {
			if (!_select){
				// update all selects on page
				self.updateAllCustomSelect();
			}
		 	else {
		 		// update single select
		 		self.updateCustomSelect(_select);
		 	}
		});

		Core.publisher.publish("onSelectChange");
	},

	updateAllCustomSelect : function()
	{
		var self = this;

		$('select').each(function() {
			self.updateCustomSelect($(this));
		});
	},

	updateCustomSelect : function(s)
	{
		var v = $(s).find('option:selected').text();
		$(s).parent().find('span.customSelectInner').html(v);
		$(s).parent().find('div.customSelectInner').html(v);
		$(s).parent().find('div.ui-text').html(v);

		var d = $(s).attr('disabled');
		if (d=='disabled') {
			$(s).parent().find('span.customSelectInner').addClass('disabledSelect');
			$(s).parent().find('div.customSelectInner').parent().addClass('disabledSelect');

			$(s).parent().find('.ui-select').addClass('disabled');
		}
		else {
			$(s).parent().find('span.customSelectInner').removeClass('disabledSelect');
			$(s).parent().find('div.customSelectInner').parent().removeClass('disabledSelect');

			$(s).parent().find('.ui-select').removeClass('disabled');
		}
	}
});


Core.createUtil ("converters", {
	init : function()
	{
		console.log("Converters LIB init");
	},

	// expected date format is dd.mm.yy
	// output dd-mm-yy
	convertDottedDateToBlikFormat : function(dateStr)
	{
		var date,
			fdate;

		try {
			date = $.datepicker.parseDate('dd.mm.yy', dateStr);
			fdate = $.datepicker.formatDate('dd-mm-yy', date);
			   }
		catch(e){
			console.log("date can't be converter", dateStr);
			fdate ="";
		 }

		 return fdate;
	},

	convertBlikDateToDottedDate: function(dateStr)
	{
		var date,
			fdate;

		try {
			date = $.datepicker.parseDate('dd-mm-yy', dateStr);
			fdate = $.datepicker.formatDate('dd.mm.yy', date);
			   }
		catch(e){
			console.log("date can't be converter", dateStr);
			fdate ="";
		 }

		 return fdate;
	},

	convertBlikDateToDotNetDate: function(dateStr)
	{
		var date,
			fdate;

		try {
			date = $.datepicker.parseDate('dd-mm-yy', dateStr);
			fdate = $.datepicker.formatDate('yy-mm-ddT00:00:00+02:00', date);
			   }
		catch(e){
			console.log("date can't be converter", dateStr);
			fdate ="";
		 }

		 return fdate;
	},


	convertBlikDateToObj: function(dateStr)
	{
		var date;

		try {
			date = $.datepicker.parseDate('dd-mm-yy', dateStr);
		}
		catch(e){
			console.log("date can't be converter", dateStr);
			date = false;
		 }

		return date;
	},

	convertDateObjToYYYYMMDD : function(dt)
	{
		var yy = dt.getFullYear();
		var mm = deal_get_zerofill( (parseInt(dt.getMonth())+1) );
		var dd = deal_get_zerofill( (dt.getDate()) );

		return yy + '-' + mm + '-' + dd;
	},

	convertDateObjToDDMMYYYY : function(dt)
	{
		var yy = dt.getFullYear();
		var mm = this._get_zerofill( (parseInt(dt.getMonth())+1) );
		var dd = this._get_zerofill( (dt.getDate()) );

		return dd + '-' + mm + '-' + yy;
	},

	convertDateObjToDDMMYY : function(dt)
	{
		var yy = dt.getFullYear().toString().slice(2);;
		var mm = this._get_zerofill( (parseInt(dt.getMonth())+1) );
		var dd = this._get_zerofill( (dt.getDate()) );

		return dd + '-' + mm + '-' + yy;
	},

	convertDateObjectToDottedFormatFull: function(date) {
		try {
			fdate = $.datepicker.formatDate('dd.mm.yy', date);
			   }
		catch(e){
			console.log("date can't be converter", dateStr);
			fdate ="";
		 }

		 return fdate;		
	},

	convertDottedDateToShortFormat : function(dateStr)
	{
		var date,
			fdate;

		try {
			date = $.datepicker.parseDate('dd.mm.yy', dateStr);
			fdate = $.datepicker.formatDate('dd.mm.y', date);
			   }
		catch(e){
			console.log("date can't be converter", dateStr);
			fdate ="";
		 }

		 return fdate;
	},

	convertMinusDateToShortFormat : function(dateStr)
	{
		var date,
			fdate;

		try {
			date = $.datepicker.parseDate('dd-mm-yy', dateStr);
			fdate = $.datepicker.formatDate('dd.mm.y', date);
			   }
		catch(e){
			console.log("date can't be converter", dateStr);
			fdate ="";
		 }

		 return fdate;
	},

	convertTimeToInt: function(timeStr, accuracy)
	{
		var time = timeStr.split(":"),
			result;

		if (accuracy == "hours") {
			result = parseInt(time[0], 10);
		}
		else if (accuracy == "minutes") {
			result = parseInt(time[0]*60, 10) + parseInt(time[1], 10);
		}
		else {
			console.log("INVALID ACCURACY: " + accuracy);
		}

		return result;
	},

	convertIntToTime: function(timeInt, accuracy)
	{
		var timeStr,
			result,
			hh,
			mm;

		if (accuracy == "hours") {
			result = timeInt.toString() + ":00";
		}
		else if (accuracy == "minutes") {
			hh = Math.floor(timeInt / 60);
			mm = (timeInt-(hh*60)).toString();
			if (mm<10) {
				mm="0"+mm;
			}

			result = hh.toString() + ":" + mm;
		}
		else {
			console.log("INVALID ACCURACY: " + accuracy);
		}

		return result;
	},

	convertIntToTimeParam: function(timeInt, accuracy)
	{
		var timeStr,
			result,
			hh,
			mm;

		if (accuracy == "hours") {
			result = timeInt.toString();
		}
		else if (accuracy == "minutes") {
			hh = Math.floor(timeInt / 60);
			mm = (timeInt-(hh*60)).toString();

			result = hh.toString() + "-" + mm;
		}
		else {
			console.log("INVALID ACCURACY: " + accuracy);
		}

		return result;
	},

	_get_zerofill: function (num)
	{
		var snum = num+'';
		return (snum.length > 1) ? snum : '0'+snum;
	}

});


Core.createUtil ("recalculator", {
	init : function()
	{
		console.log("recalculator LIB init");
	},

	recalcNofshonPaxes : function (wrap)
	{
		var self = this;

		var el = wrap;
		var max_persons_per_deal = 6;

		function recalcNofshonPaxesInit(wrap)
		{
			wrap.each(function(){
				var wr = $(this);

				$(this).find('select').each(function(){
					$(this).change(function(){recalcNofshonPaxesRecalc(wr, max_persons_per_deal);});
				 });

				 var s = $(this).find('select[name*="adults"]');
				 self.update_select_values(s, max_persons_per_deal, 2)

				 // reset kids & infants
				var s = $(wr).find('select[name*="kids"]');
				self.update_select_values(s, 0);

				var s = $(wr).find('select[name*="infants"]');
				self.update_select_values(s, 0);

				recalcNofshonPaxesRecalc(wr, max_persons_per_deal);


			});
		}

		function recalcNofshonPaxesRecalc(_selector, max_total)
		{
		    var adults_total_max = 6; 
		    var kids_total_max = 3;
			var infants_total_max = 2;

			var adults_val = _selector.find('select[name*="adults"]').val();
			var kids_val = _selector.find('select[name*="kids"]').val();
			var infants_val = _selector.find('select[name*="infants"]').val();

			/* Blik functions - Start */

			//fill adults combo - with a constant number of paxes
			var s = $(_selector).find('select[name*="adults"]');
			self.update_select_values(s, adults_total_max, null, true, " ");

			//fill childs combo - by looking on the adults_val, and then creating specific Room Occupancies
			var max_kids;
			if (adults_val <= 1 || adults_val > 4)
			    max_kids = 0;
			else if (adults_val == 2)
			    max_kids = 3;
			else if (adults_val == 3)
			    max_kids = 2;
            else if (adults_val == 4)
                max_kids = 1;

			var s = $(_selector).find('select[name*="kids"]');
			self.update_select_values(s, max_kids, null, true, " ");

			//fill infants combo - with a constant number of paxes, except for a case of no adults
			var max_infants = (adults_val != 0 ? infants_total_max : 0);
			var s = $(_selector).find('select[name*="infants"]');
			self.update_select_values(s, max_infants, null, true, " ");

			//if the infants combo has infants selected - show the Infant Note, if no infants are selected (can happen also when we empty the combo) - hide the Infant Note
			var infants_val = _selector.find('select[name*="infants"]').val();

			if (infants_val != 0) {
				Core.publisher.publish("displayInfantNote");
			}
			else {
				Core.publisher.publish("hideInfantNote");
			}

			Core.publisher.publish("onSelectChange");
			/* Blik functions - End */
		}

		recalcNofshonPaxesInit(wrap);

	},

	recalcHotelsIsrael: function(wrap)
	{
		var self = this;

		function recalcHotelsIsraelPaxesInit(wrap)
		{
			var max_total_paxes = 6;

			$(wrap).find('select').each(function(){
				$(this).change(function(){recalcHotelsIsraelPaxesRecalc($(wrap), max_total_paxes);});
			 });

			self.update_select_values($(wrap).find('.inputAdults'), 6 ,2, true, false);
			recalcHotelsIsraelPaxesRecalc($(wrap), max_total_paxes);
		}

		function recalcHotelsIsraelPaxesRecalc(_selector, max_total)
		{
		    var adults_total_max 	= 6,
		    	kids_total_max 		= 5,
				infants_total_max 	= 2,
				adults_calc,
				kids_calc,
				infants_calc,
				$adults 			= _selector.find('.inputAdults'),
			 	$kids 				= _selector.find('.inputKids'),
			 	$infants 			= _selector.find('.inputInfants');


			adults_calc = max_total-$kids.val()-$infants.val();
			adults_calc = self.getMin(adults_calc, adults_total_max);
			self.update_select_values($adults, adults_calc,null, true, false);

			kids_calc = max_total-$adults.val()-$infants.val();
			kids_calc = self.getMin(kids_calc, kids_total_max);
			self.update_select_values($kids, kids_calc,null, true,"0");

			infants_calc = max_total-$adults.val()-$kids.val();
			infants_calc = self.getMin(infants_calc, infants_total_max);
			self.update_select_values($infants, infants_calc,null, true,"0");

			Core.publisher.publish("onSelectChange");
		}

		recalcHotelsIsraelPaxesInit(wrap);
	},

	getMin: function(val1, val2)
	{
		var a = parseInt(val1, 10),
			b = parseInt(val2, 10),
			r = (a<b) ? a : b;

		return r;
	},

	update_select_values: function(s, max_val, def_val, enabledIfZero, zeroValue)
	{
		var self = this;

		var selectValues = [];

		for (var i = 1; i<=max_val; i++) {
			selectValues[i] = i;
		}

		var v = (s.val() < max_val) ? s.val() : max_val;

		if (def_val) {
			v = (def_val < max_val) ? def_val : max_val;
		}

			$(s)
				.find('option')
				.remove()
				.end();

		if (zeroValue !== false) {
			$(s)
				.append('<option value="0">'+((zeroValue) ? zeroValue : "בחר") +'</option>')
				.val('0');
		}

		for (var i in selectValues) {
			$(s).
			append($("<option></option>").
			attr("value",i).
			text(selectValues[i]));
		}

		$(s).val(v);

		if (!enabledIfZero && max_val == 0) {
			self.DisableCustomSelect(s);
		}
		else {
			self.EnableCustomSelect(s);
		}

		Core.publisher.publish("onSelectChange");
	},

	DisableCustomSelect: function(selector)
	{
		$(selector).attr('disabled', 'disabled');
		$(selector).parent().find('.customSelectInner').addClass('disabledSelect');
	},

	EnableCustomSelect: function(selector)
	{
		$(selector).removeAttr('disabled');
		$(selector).parent().find('.customSelectInner').removeClass('disabledSelect');
	}
});

Core.createUtil ("pager", {
	init: function()
	{
		console.log("Pager LIB init");
	},

	calc: function(pagerData, maxPages)
	{
		var self = this,
			pages = [],
			p = pagerData;
			console.log(p);

		p.maxPage = Math.ceil(p.totalResults / p.pageSize);

		for (var i=1; i<= p.maxPage; i++) {
			if (self.isPageInRange(i, p.pageSelected, maxPages, p.maxPage)) {
				pages.push(i);
			}
		}

		p.pages = pages;
		p.itemFrom = (p.pageSize * (p.pageSelected-1) +1);
		p.itemFrom = (p.totalResults == 0 ) ? 0 : p.itemFrom;
		p.itemTo = p.itemFrom + p.pageSize-1;
		p.itemTo = (p.itemTo > p.totalResults) ? p.totalResults : p.itemTo;
		p.itemsText = p.itemFrom + "-" + p.itemTo;

		p.previousPage = (p.pageSelected-1 > 0) ? (p.pageSelected-1) : 1;
		p.nextPage =(p.pageSelected+1 < p.maxPage) ? (p.pageSelected+1) : p.maxPage;

		return p;
	},

	isPageInRange: function (pageNum, selectedPage, maxPages, totalPages)
	{
		var maxLeft = parseInt(selectedPage) - Math.floor((parseInt(maxPages)-1) / 2),
			leftOff = (maxLeft<=0) ? -maxLeft+1 : 0,
			maxRight = parseInt(leftOff) + parseInt(selectedPage) + Math.ceil((parseInt(maxPages)-1) / 2),
			rightOff = (maxRight>parseInt(totalPages)) ? maxRight-parseInt(totalPages) : 0;

		maxRight = (maxRight<parseInt(totalPages)) ? maxRight : parseInt(totalPages);
		maxLeft = (maxLeft-rightOff<=0) ? 1 : (maxLeft-rightOff);


		if ((pageNum >= maxLeft) && (pageNum <= maxRight)) {
			return true;
		} else {
			return false;
		}
	}

});


Core.createUtil ("select", {
	init: function()
	{
		console.log("Select LIB init");
	},

	setSelectOptions: function($select, selectValues, defaultValue, keyName, keyValue, excludeArr)
	{
		var i, name, val, obj;

		$select.find('option').remove();

		var exclude = (excludeArr) ? excludeArr : [];
		this.addSelectOptions ($select, selectValues, defaultValue, keyName, keyValue, exclude)
	},

	addSelectOptions: function($select, selectValues, defaultValue, keyName, keyValue, excludeArr)
	{
		var i, name, val, obj,
			exclude = (excludeArr) ? excludeArr : [];

		for (i in selectValues) {

			obj = selectValues[i];

			name = (keyName) ? obj[keyName] : ((obj.name) ? obj.name : "");
			val = (keyValue) ? obj[keyValue] : ((obj.val) ? obj.val : "");

			if ($.inArray( val, exclude )==-1) {
				$select.
				  append($("<option></option>").
				  attr("value",val).
				  text(name));
			}

		}

		if(defaultValue) {
			$select.val(defaultValue);
		}
	},

	isOptionExists: function($select, optionValue)
	{
		var $o = $select.find("option[value='"+optionValue+"']");

		return ($o.length>0) ? true : false;
	}
});

Core.createUtil ("cookie", {
	init: function()
	{
		console.log("Cookie LIB init");

	},

	getCookie: function(namespace, cookieName, defaultValue)
	{
		var fullName = namespace.toString() + "_" + cookieName.toString(),
			value = $.cookie(fullName);

		if (typeof value == "undefined") {

			return defaultValue;
		}

		return value;
	},

	getValidCookieDate: function(namespace, cookieName, defaultValue)
	{
		/* using Date.js will cause this function not working */

		var conv = Core.utils.converters,
			date = this.getCookie(namespace, cookieName, defaultValue);

		if (!date) {
			return defaultValue;
		}

		var dateObj = conv.convertBlikDateToObj(date);

		if (!date) {
			return defaultValue;
		}

		var now = moment(Core.getCurrentDateTime()),
			cookieDate = moment(dateObj),
			isBefore = cookieDate.isBefore(now,'days');


		if (isBefore) {
			console.log("invalid date");
			return defaultValue;
		}

		return date;
	},

	setCookie: function(namespace, cookieName, value, isLocal)
	{
		var expiration = Core.getPageConfigValue("COOKIE_EXPIRATION_IN_DAYS", 3),
			fullName = namespace.toString() + "_" + cookieName.toString();

		if (!isLocal) {
			$.cookie(fullName, value, { expires: expiration, path: '/' });
		}
		else {
			$.cookie(fullName, value, { expires: expiration });
		}
	},

	removeCookie: function(namespace, cookieName, isLocal)
	{
		var fullName = namespace.toString() + "_" + cookieName.toString();

		if (!isLocal) {
			$.removeCookie(fullName, { path: '/' });
		}
		else {
			$.removeCookie(fullName);
		}
	}

});


Core.createUtil ("slider", {
	init: function()
	{
		console.log("Slider LIB init");

	},

	createSlider: function(options)
	{
		var defaults = {
			direction: "right",
			btnLeft : null,
			btnRight : null,
			easing: "easeOutCubic",
			animationTime: 600,
			slideWidth: 0,
			slidesCount: 0,
			slidesVisible: 0,
			element : null,
			onComplete:null

		};

		var slider = function(options)
		{
			var self = this,
				settings = $.extend({}, defaults, options),
				currentOffset = 0;

				settings.btnLeft.hide();
				settings.btnRight.hide();


			self.updateButtons = function()
			{

				if (settings.slidesCount<=settings.slidesVisible)
				{
					settings.btnLeft.fadeOut();
					settings.btnRight.fadeOut();

					return;
				}

				if (currentOffset==0) {
					settings.btnRight.fadeOut();
				}
				else {
					settings.btnRight.fadeIn();
				}

				if (settings.slidesCount-settings.slidesVisible-currentOffset==0) {
					settings.btnLeft.fadeOut();
				}
				else {
					settings.btnLeft.fadeIn();
				}
			}

			self.showSlide = function(num, fast)
			{
				var lastVisible = ((settings.slidesVisible-1)+currentOffset)
				if (num<lastVisible && num>=currentOffset) {
					return;
				}

				var minOffset = (num-(settings.slidesVisible-1));
				minOffset = (minOffset<0) ? 0 : minOffset;

				currentOffset = minOffset;
				var val = settings.slideWidth*currentOffset* (-1);

				if (!fast) {
					settings.element.animate({
			    		right: val
						  }, settings.animationTime, settings.easing, function() {
						  	if (settings.onComplete) {
						  		settings.onComplete();
						  	}
						  });
				} else {
					settings.element.css("right", val);
				  	if (settings.onComplete) {
				  		settings.onComplete();
				  	}					
				}

				self.updateButtons();
			}

			// controls
			$(settings.btnLeft).unbind().click(function(e)
			{
				e.preventDefault();

				console.log("btnLeft");

				if (settings.slidesCount-settings.slidesVisible-currentOffset > 0) {

					currentOffset++;

					settings.element.animate({
			    		right: "-="+settings.slideWidth.toString()
						  }, settings.animationTime, settings.easing, function() {
						  	if (settings.onComplete) {
						  		settings.onComplete();
						  	}
						  });
				}

				self.updateButtons();
			});

			$(settings.btnRight).unbind().click(function(e)
			{
				e.preventDefault();

				console.log("btnRight");

				if (currentOffset > 0) {

					currentOffset--;

					settings.element.animate({
			    		right: "+="+settings.slideWidth.toString()
						  }, settings.animationTime, settings.easing, function() {
						  	if (settings.onComplete) {
						  		settings.onComplete();
						  	}
						  });
				}

				self.updateButtons();
			});

			self.updateButtons();
		}


		var o = new slider(options);
		return o;
	}
});

Core.createUtil ("filterData", {
	init: function()
	{
		console.log("filterData LIB init");

	},

	getDataForSelect : function(data)
	{
		var self = this;
		var a = [];
		var selected = null;

		for (var i in data) {
			h = data[i];
			var r = this.makeSelectValue(h.value, h.text)
			a.push(r);

			if (typeof (h.selected) !== 'undefined' && (h.selected == true || h.selected == 'true')) {

				selected = h.value;
			}
		}

		return {
			options : a,
			selected : selected
		} ;
	},

	makeSelectValue : function(a, b)
	{
		return {val: a, name: b};
	},
});


Core.createUtil("paxTools", {
	init: function()
	{
		console.log("paxTools LIB init");

	},

	getFullPaxDescriptionString : function(adultsStr, kidsStr, infantsStr, youngsStr,studentsStr, seniorsStr)
	{
		var paxTypes = [
				{
					s: "מבוגר",
					m: "מבוגרים"
				},
				{
					s: "ילד",
					m: "ילדים"
				},
				{
					s: "תינוק",
					m: "תינוקות"
				},
				{
					s: "צעיר",
					m: "צעירים"
				},

				{
					s: "סטודנט",
					m: "סטודנטים"
				},
				{
					s: "גיל הזהב",
					m: "גיל הזהב"
				}
			];

		var values = [adultsStr, kidsStr, infantsStr, youngsStr,studentsStr, seniorsStr],
			line,
			str = '',
			i;

        for (i in paxTypes) {

        	if (parseInt(values[i])==0) {
        		line = null;

        		if (parseInt(values[i])==1) {
        			line = values[i].toString() + ' ' + paxTypes[i].s;
        		}
        		else {
        			line = values[i].toString() + ' ' + paxTypes[i].m;
        		}
        	}

        	switch(parseInt(values[i]))
        	{
        		case 0:
        			line = null;
        		break;

        		case 1:
        			line = values[i].toString() + ' ' + paxTypes[i].s;
        		break;

        		default:
        			line = values[i].toString() + ' ' + paxTypes[i].m;
        	}

        	str += (line) ? ((str=="") ? line : " + " + line) : '';
        }


        return str;
	}
});

Core.createUtil("image", {
	init: function()
	{
		console.log("image tools LIB init");

	},

	preloadImages: function(images, callback)
	{
		var total = images.length,
			count = 0;

		for (var i in images) {
			var crImg = new Image();

			(function (img, src){
				$(img).load(function(){

					console.log("image loaded", src);

					count ++;
					if (count==total && callback) {
						callback();
					}
				});

				img.src = src;
			})(crImg, images[i]);

		}
	}
});

Core.createUtil("text", {
	init: function()
	{
		console.log("text tools LIB init");

	},

	isEnglishText: function (str)
	{
		var self = this,
			rforeign = /[^\u0000-\u007f]/;

		return (!rforeign.test(str));
	},

	fixTextDirection: function(el, text)
	{
		var self = this,
			tt = (text) ? text : $(el).html()

		if (self.isEnglishText(tt)) {
			$(el).attr('dir', 'ltr');
		}
		else {
			$(el).attr('dir', 'rtl');
		}
	}

});
